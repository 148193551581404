<template>
  <el-dialog title="编辑存款" :visible.sync="dialogVisible" width="79%" :before-close="handleClose">
    <el-form v-model="editFrom" label-width="100px">
      <!--  水费  -->
      <el-row :gutter="21">
        <el-col :span="7">
          <el-form-item label="存款类型" width="120px">
            <el-input width="20px" v-model="editFrom.bankType" disabled></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="7">
          <el-form-item label="银行编码" width="120px">
            <el-input width="20px" v-model="editFrom.bankCode" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!--  电费  -->
      <el-row :gutter="21">
        <el-col :span="7">
          <el-form-item label="支行名称" width="120px">
            <el-input width="20px" v-model="editFrom.subbranch"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="用途" width="120px">
            <el-input width="20px" v-model="editFrom.purpose"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="备注" width="80px">
            <el-input width="20px" v-model="editFrom.remark"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="editBankButton()">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { formatDate } from "@/plugins/data.js";
import { getSelectById, editBank } from "@/api/capital/bank/bank";
export default {
  name: "EditBank",
  data() {
    return {
      dialogVisible: false,
      editFrom: {}
    };
  },
  methods: {
    //打开弹窗
    showFund(id) {
      this.dialogVisible = true;
      this.getSelectByIdButton(id);
    },
    getSelectByIdButton(id) {
      getSelectById(id).then(res => {
        if (res.code !== '200') return this.$message.error("获取基金信息失败");
        this.editFrom = res.result;
      });
    },
    editBankButton() {
      editBank(this.editFrom).then(res => {
        console.log(res);
        if (res.code !== '200') return this.$message.error("获取基金信息失败");
        console.log(res);
        this.dialogVisible = false;
        this.$parent.getBankList();
      });
    },
    ok() {
      this.$emit("closeModal", false);
      this.$Message.info("Clicked ok");
    },
    cancel() {
      this.$emit("closeModal", false);
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    }
  }
};
</script>
<style lang="less" scoped>
</style> 